.modal-file {
	position: fixed;
	z-index: 1000;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.9);
}

.modal-file-head {
	background: linear-gradient(0deg, rgb(0 0 0 / 0%) 0%, rgba(0, 0, 0, 1) 100%);
}

.modal-file-body {
	margin-left: 10%;
	width: 80%;
	background: rgb(255, 255, 255);
	height: 90%;
}

.btn-modal-file {
	cursor: pointer;
	padding: 5px 15px 5px 15px;
}

.btn-modal-file:hover {
	border-radius: 2px;
	background-color: rgb(255 255 255 / 25%);
}

@media only screen and (max-width: 600px) {
	.modal-file-body {
		margin-top: 5%;
		margin-left: 5%;
		width: 90%;
	}

}