.popup-apsolute {
	position: fixed;
	transition: opacity 2.5s;
	opacity: 2;
	z-index: 1065;
	top: 0%;
	left: 0%;
	width: 100%;
	height: calc(100vh);
	background-color: rgb(58 59 69 / 75%);
	overflow: hidden;
}

.popup-apsolute.hide {
	opacity: 0;
	pointer-events: none;
	display: none;
}

.popup-apsolute .popup-body {
	margin: 15%;
}