.modal-middle {
	text-align: center;
}

@media screen and (min-width: 768px) {
	.modal-middle:before {
		display: inline-block;
		vertical-align: middle;
		content: " ";
		height: 100%;
	}
}

.modal-middle-dialog {
	display: inline-block;
	text-align: left;
	vertical-align: middle;
}
