@import url('https://fonts.googleapis.com/css?family=Kanit:regular,bold,italic&subset=latin,latin-ext');

body {
	background-color: transparent;
	font-family: Kanit;
}

select.kanit {
	font-family: Kanit !important;
}

.sidebar {
	z-index: 2;
}

.font-8 {
	font-size: 8px;
}

.font-9 {
	font-size: 9px;
}

.font-10 {
	font-size: 10px;
}

.font-11 {
	font-size: 11px;
}

.font-12 {
	font-size: 12px;
}

.font-13 {
	font-size: 13px;
}

.max-900 {
	max-width: 900px;
	margin: auto;
}

.max-1024 {
	max-width: 1024px;
	margin: auto;
}

input.transparent-input {
	background-color: transparent !important;
	border: none !important;
}

.search-item {
	transition: opacity 5.5s;
	opacity: 2;
	position: fixed;
	top: 0%;
	left: 0%;
	z-index: 10 !important;
	width: 100%;
	height: 100%;
	min-height: 400px;
	background: hsl(0deg 0% 17% / 91%);
	padding: 5% !important;
	overflow: auto;
}

.search-item.hide {
	opacity: 0;
	pointer-events: none;
}

.only-print {
	display: none;
}

@media print {
	.pagebreak {
		clear: both;
		page-break-after: always;
	}

	.only-print {
		display: block;
	}

	.disabled-print {
		display: none;
	}

	.d-print-noline {
		border: 0;
		resize: none;
	}

	textarea {
		resize: none;
	}

	select {
		/*hidden arrow down*/
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 1px;
		text-overflow: '';
	}

}

.modal.modal-fullscreen .modal-dialog {
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
	max-width: none;
}

.modal.modal-fullscreen .modal-content {
	height: auto;
	height: 100vh;
	border-radius: 0;
	border: none;
}

.modal.modal-fullscreen .modal-body {
	overflow-y: auto;
}

.textLayer {
	display: none;
}

.overflowx-auto {
	overflow-x: auto;
}

.cursor-pointer {
	cursor: pointer;
}


.blink {
	animation: blinker 1s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.row-hover div {
	padding-top: 10px;
	padding-left: 10px;
}

.row-hover:hover {
	background-color: rgb(130 162 255 / 50%);
	cursor: pointer;
	color: #fff;
}

.dropdown-menu {
	overflow-y: auto;
	max-height: 300px;
}

.img-shadow {
	filter: drop-shadow(9px 9px 9px rgba(0, 0, 0, 0.3));
}

.relative {
	position: relative;
}

.bottom-fixed {
	width: 100vw;
	position: fixed;
	z-index: 1;
	left: 0%;
	bottom: 0px;
	transform: translate(0%, 0%);
}

.bottom-fixed>.left {
	width: 14rem !important;
	background: transparent;
}

.bottom-fixed>.right {
	padding: 5px 20px 5px 5px;
	background-color: #3a3b45d6 !important;
	border: 1px solid rgb(10, 21, 186);
	width: 100%;
	/* margin: 0 15px 0 14rem; */
}

.delete-row {
	position: absolute;
	right: -30px;
	top: 7px;
}

.absolute-card {
	position: fixed;
	z-index: 1;
	left: 30%;
	top: 25%;
	width: 40%;
	height: 20%;
	text-align: center;
}

.d-show {
	opacity: 1;
}

.d-show.hide {
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
	opacity: 0;
}


.text-purple {
	color: purple;
}

.text-magenta {
	color: magenta;
}

.search-order-result {
	position: absolute;
	left: 0px;
	top: 36%;
	width: 100%;
	background-color: rgba(54, 61, 127, 0.82);
	color: rgb(255, 255, 255);
	z-index: 1061;
	padding: 15px;
	border: 1px solid rgb(204, 204, 204);
	border-radius: 5px;
}

.bg-red-light {
	background-color: rgb(253, 235, 235);
}

iframe>html>body>img {
	width: 100% !important;
}

.modal-search-items {
	position: fixed;
	z-index: 1002;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.93);
	color: rgb(255, 255, 255);
	overflow-y: auto;
}

.react-datepicker-popper {
	z-index: 3 !important;
}

.input-group-append {
	/* z-index: 1 !important; */
}

.p2-em {
	padding: 0.2rem !important;
}

.p3-em {
	padding: 0.3rem !important;
}


.gradient-buttons .btn {
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.5), rgba(255,255,255,0.2) 49%, rgba(0,0,0,0.15) 51%, rgba(0,0,0,0.05));
    background-repeat: repeat-x;
}